/**
 * Octicons2 icon set component.
 * Usage: <Octicons2 name="icon-name" size={20} color="#4F8EF7" />
 */

import createIconSet from 'react-native-vector-icons/lib/create-icon-set'
const glyphMap = {
  'alert-24': 59648,
  'archive-24': 59649,
  'arrow-both-24': 59650,
  'arrow-down-24': 59651,
  'arrow-down-left-24': 59652,
  'arrow-down-right-24': 59653,
  'arrow-left-24': 59654,
  'arrow-right-24': 59655,
  'arrow-up-24': 59656,
  'arrow-up-left-24': 59657,
  'arrow-up-right-24': 59658,
  'beaker-24': 59659,
  'bell-24': 59660,
  'bell-fill-24': 59661,
  'bell-slash-24': 59662,
  'bold-24': 59663,
  'book-24': 59664,
  'bookmark-24': 59665,
  'bookmark-fill-24': 59666,
  'bookmark-slash-24': 59667,
  'bookmark-slash-fill-24': 59668,
  'broadcast-24': 59669,
  'calendar-24': 59670,
  'check-24': 59671,
  'check-circle-24': 59672,
  'check-circle-fill-24': 59673,
  'checklist-24': 59674,
  'chevron-down-24': 59675,
  'chevron-left-24': 59676,
  'chevron-right-24': 59677,
  'chevron-up-24': 59678,
  'circle-24': 59679,
  'circle-slash-24': 59680,
  'clippy-24': 59681,
  'clock-24': 59682,
  'code-24': 59683,
  'code-review-24': 59684,
  'code-square-24': 59685,
  'comment-24': 59686,
  'comment-discussion-24': 59687,
  'commit-24': 59688,
  'copy-24': 59689,
  'cpu-24': 59690,
  'credit-card-24': 59691,
  'cross-reference-24': 59692,
  'dash-24': 59693,
  'database-24': 59694,
  'desktop-download-24': 59695,
  'device-camera-video-24': 59696,
  'device-desktop-24': 59697,
  'device-mobile-24': 59698,
  'diff-24': 59699,
  'dot-24': 59700,
  'dot-fill-24': 59701,
  'download-24': 59702,
  'eye-24': 59703,
  'eye-slash-24': 59704,
  'file-24': 59705,
  'file-binary-24': 59706,
  'file-code-24': 59707,
  'file-diff-24': 59708,
  'file-directory-24': 59709,
  'file-directory-fill-24': 59710,
  'file-media-24': 59711,
  'file-submodule-24': 59712,
  'file-symlink-24': 59713,
  'file-zip-24': 59714,
  'filter-24': 59715,
  'fire-24': 59716,
  'fold-24': 59717,
  'fold-down-24': 59718,
  'fold-up-24': 59719,
  'gear-24': 59720,
  'gift-24': 59721,
  'git-branch-24': 59722,
  'git-commit-24': 59723,
  'git-compare-24': 59724,
  'git-fork-24': 59725,
  'git-merge-24': 59726,
  'git-pull-request-24': 59727,
  'globe-24': 59728,
  'grabber-24': 59729,
  'graph-24': 59730,
  'heading-24': 59731,
  'heart-24': 59732,
  'heart-fill-24': 59733,
  'history-24': 59734,
  'home-24': 59735,
  'home-fill-24': 59736,
  'horizontal-rule-24': 59737,
  'hourglass-24': 59738,
  'hubot-24': 59739,
  'image-24': 59740,
  'inbox-24': 59741,
  'infinity-24': 59742,
  'info-24': 59743,
  'insights-24': 59744,
  'issue-closed-24': 59745,
  'issue-opened-24': 59746,
  'issue-reopened-24': 59747,
  'italic-24': 59748,
  'kebab-horizontal-24': 59749,
  'key-24': 59750,
  'law-24': 59751,
  'light-bulb-24': 59752,
  'link-24': 59753,
  'link-external-24': 59754,
  'list-ordered-24': 59755,
  'list-unordered-24': 59756,
  'location-24': 59757,
  'lock-24': 59758,
  'mail-24': 59759,
  'megaphone-24': 59760,
  'mention-24': 59761,
  'milestone-24': 59762,
  'mirror-24': 59763,
  'mortar-board-24': 59764,
  'mute-24': 59765,
  'no-entry-24': 59766,
  'north-star-24': 59767,
  'note-24': 59768,
  'organization-24': 59769,
  'package-24': 59770,
  'package-dependencies-24': 59771,
  'package-dependents-24': 59772,
  'paper-airplane-24': 59773,
  'pencil-24': 59774,
  'people-24': 59775,
  'person-24': 59776,
  'pin-24': 59777,
  'play-24': 59778,
  'plug-24': 59779,
  'plus-24': 59780,
  'plus-circle-24': 59781,
  'project-24': 59782,
  'pulse-24': 59783,
  'question-24': 59784,
  'quote-24': 59785,
  'reply-24': 59786,
  'repo-24': 59787,
  'repo-push-24': 59788,
  'repo-template-24': 59789,
  'report-24': 59790,
  'rocket-24': 59791,
  'rss-24': 59792,
  'screen-full-24': 59793,
  'screen-normal-24': 59794,
  'search-24': 59795,
  'server-24': 59796,
  'settings-24': 59797,
  'share-24': 59798,
  'share-android-24': 59799,
  'shield-24': 59800,
  'shield-check-24': 59801,
  'shield-lock-24': 59802,
  'shield-x-24': 59803,
  'sign-in-24': 59804,
  'sign-out-24': 59805,
  'skip-24': 59806,
  'smiley-24': 59807,
  'star-24': 59808,
  'star-fill-24': 59809,
  'stop-24': 59810,
  'stopwatch-24': 59811,
  'sync-24': 59812,
  'tab-24': 59813,
  'tag-24': 59814,
  'tasklist-24': 59815,
  'telescope-24': 59816,
  'terminal-24': 59817,
  'thumbsdown-24': 59818,
  'thumbsup-24': 59819,
  'tools-24': 59820,
  'trash-24': 59821,
  'triangle-down-24': 59822,
  'triangle-left-24': 59823,
  'triangle-right-24': 59824,
  'triangle-up-24': 59825,
  'typography-24': 59826,
  'unfold-24': 59827,
  'unlock-24': 59828,
  'unmute-24': 59829,
  'unverifed-24': 59830,
  'upload-24': 59831,
  'verified-24': 59832,
  'versions-24': 59833,
  'workflow-24': 59834,
  'x-24': 59835,
  'x-circle-24': 59836,
  'x-circle-fill-24': 59837,
  'alert-16': 59838,
  'archive-16': 59839,
  'arrow-both-16': 59840,
  'arrow-down-16': 59841,
  'arrow-left-16': 59842,
  'arrow-right-16': 59843,
  'arrow-up-16': 59844,
  'beaker-16': 59845,
  'bell-16': 59846,
  'bell-slash-16': 59847,
  'bold-16': 59848,
  'book-16': 59849,
  'bookmark-16': 59850,
  'bookmark-slash-16': 59851,
  'broadcast-16': 59852,
  'calendar-16': 59853,
  'check-16': 59854,
  'check-circle-16': 59855,
  'check-circle-fill-16': 59856,
  'checklist-16': 59857,
  'chevron-down-16': 59858,
  'chevron-left-16': 59859,
  'chevron-right-16': 59860,
  'chevron-up-16': 59861,
  'circle-slash-16': 59862,
  'clippy-16': 59863,
  'clock-16': 59864,
  'code-16': 59865,
  'code-review-16': 59866,
  'code-square-16': 59867,
  'comment-16': 59868,
  'comment-discussion-16': 59869,
  'cpu-16': 59870,
  'credit-card-16': 59871,
  'cross-reference-16': 59872,
  'dash-16': 59873,
  'database-16': 59874,
  'desktop-download-16': 59875,
  'device-camera-video-16': 59876,
  'device-desktop-16': 59877,
  'device-mobile-16': 59878,
  'diff-16': 59879,
  'diff-added-16': 59880,
  'diff-ignored-16': 59881,
  'diff-modified-16': 59882,
  'diff-removed-16': 59883,
  'diff-renamed-16': 59884,
  'dot-16': 59885,
  'dot-fill-16': 59886,
  'download-16': 59887,
  'eye-16': 59888,
  'eye-closed-16': 59889,
  'file-16': 59890,
  'file-binary-16': 59891,
  'file-code-16': 59892,
  'file-diff-16': 59893,
  'file-directory-16': 59894,
  'file-submodule-16': 59895,
  'file-symlink-file-16': 59896,
  'file-zip-16': 59897,
  'filter-16': 59898,
  'flame-16': 59899,
  'fold-16': 59900,
  'fold-down-16': 59901,
  'fold-up-16': 59902,
  'gear-16': 59903,
  'gift-16': 59904,
  'git-branch-16': 59905,
  'git-commit-16': 59906,
  'git-compare-16': 59907,
  'git-merge-16': 59908,
  'git-pull-request-16': 59909,
  'globe-16': 59910,
  'grabber-16': 59911,
  'graph-16': 59912,
  'heading-16': 59913,
  'heart-16': 59914,
  'heart-fill-16': 59915,
  'history-16': 59916,
  'home-16': 59917,
  'horizontal-rule-16': 59918,
  'hourglass-16': 59919,
  'hubot-16': 59920,
  'image-16': 59921,
  'inbox-16': 59922,
  'infinity-16': 59923,
  'info-16': 59924,
  'issue-closed-16': 59925,
  'issue-opened-16': 59926,
  'issue-reopened-16': 59927,
  'italic-16': 59928,
  'kebab-horizontal-16': 59929,
  'key-16': 59930,
  'law-16': 59931,
  'light-bulb-16': 59932,
  'link-16': 59933,
  'link-external-16': 59934,
  'list-ordered-16': 59935,
  'list-unordered-16': 59936,
  'location-16': 59937,
  'lock-16': 59938,
  'logo-gist-16': 59939,
  'logo-github-16': 59940,
  'mail-16': 59941,
  'mark-github-16': 59942,
  'markdown-16': 59943,
  'megaphone-16': 59944,
  'mention-16': 59945,
  'milestone-16': 59946,
  'mirror-16': 59947,
  'mortar-board-16': 59948,
  'mute-16': 59949,
  'no-entry-16': 59950,
  'north-star-16': 59951,
  'note-16': 59952,
  'octoface-16': 59953,
  'organization-16': 59954,
  'package-16': 59955,
  'package-dependencies-16': 59956,
  'package-dependents-16': 59957,
  'paper-airplane-16': 59958,
  'pencil-16': 59959,
  'people-16': 59960,
  'person-16': 59961,
  'pin-16': 59962,
  'play-16': 59963,
  'plug-16': 59964,
  'plus-16': 59965,
  'plus-circle-16': 59966,
  'project-16': 59967,
  'pulse-16': 59968,
  'question-16': 59969,
  'quote-16': 59970,
  'reply-16': 59971,
  'repo-16': 59972,
  'repo-forked-16': 59973,
  'repo-push-16': 59974,
  'repo-template-16': 59975,
  'report-16': 59976,
  'rocket-16': 59977,
  'rss-16': 59978,
  'screen-full-16': 59979,
  'screen-normal-16': 59980,
  'search-16': 59981,
  'server-16': 59982,
  'share-16': 59983,
  'share-android-16': 59984,
  'shield-16': 59985,
  'shield-check-16': 59986,
  'shield-lock-16': 59987,
  'shield-x-16': 59988,
  'sign-in-16': 59989,
  'sign-out-16': 59990,
  'skip-16': 59991,
  'smiley-16': 59992,
  'star-16': 59993,
  'star-fill-16': 59994,
  'stop-16': 59995,
  'stopwatch-16': 59996,
  'sync-16': 59997,
  'tag-16': 59998,
  'tasklist-16': 59999,
  'telescope-16': 60000,
  'terminal-16': 60001,
  'three-bars-16': 60002,
  'thumbsdown-16': 60003,
  'thumbsup-16': 60004,
  'tools-16': 60005,
  'trashcan-16': 60006,
  'triangle-down-16': 60007,
  'triangle-left-16': 60008,
  'triangle-right-16': 60009,
  'triangle-up-16': 60010,
  'typography-16': 60011,
  'unfold-16': 60012,
  'unlock-16': 60013,
  'unmute-16': 60014,
  'unverified-16': 60015,
  'upload-16': 60016,
  'verified-16': 60017,
  'versions-16': 60018,
  'workflow-16': 60019,
  'x-16': 60020,
}

const iconSet = createIconSet(glyphMap, 'Octicons2', 'Octicons2.ttf')

export default iconSet

export const Button = iconSet.Button
export const TabBarItem = iconSet.TabBarItem
export const TabBarItemIOS = iconSet.TabBarItemIOS
export const ToolbarAndroid = iconSet.ToolbarAndroid
export const getImageSource = iconSet.getImageSource
